import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Image, Icon, Button, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { TiArrowDownOutline } from "react-icons/ti";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				GearGurus
			</title>
			<meta name={"description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:title"} content={"GearGurus"} />
			<meta property={"og:description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
		</Helmet>
		<Components.Header2 />
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z) 50% 15%/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-primary"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text
							as="h1"
							margin="auto 0 auto 0"
							font="--headline1"
							lg-font="--headline2"
							text-transform="uppercase"
							align-self="center"
						>
							ЕКСПЕРТ ДЛЯ ВАШОЇ ПОЇЗДКИ
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Працюємо на досконалості, підживлюємось пристрастю
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Ласкаво просимо до GearGurus, вашого надійного партнера з обслуговування та ремонту автомобілів! Ми прагнемо забезпечити безперебійну роботу вашого автомобіля завдяки індивідуальному підходу та неперевершеній увазі до деталей. Незалежно від того, чи потрібна вам регулярна перевірка, швидкий ремонт чи капітальний ремонт, ми тут, щоб зробити ваш досвід без стресу та задоволенням.
			</Text>
			<Box
				display="flex"
				margin="40px 0 20px 0"
				justify-content="center"
				sm-flex-direction="column"
				align-self="center"
				align-content="center"
				align-items="center"
			>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13:00:58.919Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiArrowDownOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Планування без проблем
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Приєднайтеся до нашої легкої системи онлайн-бронювання.
						</Text>
					</Box>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13:00:58.880Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiArrowDownOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Прозоре ціноутворення
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Тут немає сюрпризів; тільки чесна робота за доступними цінами.
						</Text>
					</Box>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13:00:58.874Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/1-1%20%281%29.jpg?v=2024-03-14T13%3A00%3A58.874Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiArrowDownOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Якісні запчастини та обслуговування
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Ми використовуємо запчастини найвищої якості, щоб ваш автомобіль працював як новий.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 1rem 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
				>
					Надійна експертиза
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 1rem 0px" color="--darkL2" font="--lead" md-text-align="center">
						Наші технічні фахівці привносять багаторічний досвід у ваші послуги.
					</Text>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13:00:58.872Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Button type="link" href="/services" text-align="center" text-decoration-line="initial">
						Наші Послуги
					</Button>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						margin="0px 0px 40px 0px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13:00:58.872Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Потрібні маршрути чи є запитання? Наша дружня команда завжди готова прийти на допомогу. Ми лише телефонуємо або натискаємо, щоб повернути вас на дорогу.{"\n"}
						<br />
						<br />
						<Strong>
							{"\n"}Ми цінуємо наших клієнтів і ставимося до вас як до рідних.
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4">
				0975627271
				<br />
				<br />
				Mykoly Rudenka St, 29А, Dnipro,{" "}
				<br />
				Dnipropetrovsk Oblast, 49000
				<br />
				<br />
				Copyright @2024. GearGurus
			</Override>
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});